(function() {
  function rotate(e){
    const cardItem = this.querySelector(".card__inner");
    const force = 5;
    const offsetY = -(e.offsetY - cardItem.offsetHeight/2)/force;
    const offsetX = (e.offsetX - cardItem.offsetWidth/2)/force;
    cardItem.style.transform = 'rotateX(' + offsetY + 'deg) rotateY(' + offsetX + 'deg)';
  }

  function clearRotate(e){
    if(e.target.classList.contains("card")){
      const cardItem = this.querySelector(".card__inner");
      cardItem.style.transform = 'rotateX(0) rotateY(0)';
    }
  }

  const card = document.querySelector('.card');
  if (!!card) {
    card.addEventListener('mousemove', rotate);
    card.addEventListener('touchstart', rotate);
    card.addEventListener('mouseleave', clearRotate);
    card.addEventListener('touchend', clearRotate);
  }
})();
